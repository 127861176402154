import BasePlugin from '../BasePlugin'

export default class ExportScenarioCognosCommandFromAdjustmentRequest extends BasePlugin {
  async execute () {
    // this.context.$loading()
    let me = this
    let scenarioId = this.context.getModel().attr_4681_
    let card = this.context.getCard()
    card.setLoading(true)
    let address = `${this.context.$config.api}/registryservice/plugins/execute/ExportScenarioAsyncCommand`
    this.context.$http({
      method: 'post',
      url: address,
      responseType: 'arraybuffer',
      data: { 'scenario_ids': [scenarioId] }
    }).then(function (response2) {
      card.setLoading(false)
      me.openExportAttachment(response2.data, me, 'xlsx')
    }, (response) => { card.setLoading(false) })
  }
}
